<template>
  <b-card body>
    <div v-if="!menuAddProduct">
      <div class="d-flex justify-between mb-2">
        <div class="d-flex items-center">
          <b-button
            variant="primary"
            size="sm"
            class="mr-1 rounded-lg p-0"
            @click="$router.go(-1)"
          >
            <feather-icon
              size="2x"
              icon="ChevronLeftIcon"
            />
          </b-button>
          <h4 class="font-bold text-black d-inline-flex mb-0">
            Ajukan Penarikan Barang
          </h4>
        </div>
        <b-button
          :variant="handleDisableAjukan() ? 'secondary' : 'primary'"
          :disabled="handleDisableAjukan()"
          @click="confirmAjukan"
        >
          Ajukan Penarikan Barang
        </b-button>
      </div>
      <div class="flex items-center">
        <div class="w-[20%] text-[16px] text-black">
          Dikirim dari
        </div>
        <v-select
          v-model="warehouse"
          placeholder="Pilih Gudang"
          class="w-[70%]"
          :options="gudangList"
          :reduce="gudang => gudang.value"
          :selectable="gudang => !gudang.disabled"
          :clearable="false"
          @input="fetchData(), stokProduk = []"
        />
      </div>
      <div class="flex items-center mt-1">
        <div class="w-[20%]" />
        <div class="w-[70%] d-flex">
          <b-button
            :variant="ekspedisi ? 'outline-primary' : 'outline-dark'"
            class="d-flex align-items-center mr-1"
            size="sm"
            @click="onClickEkspedisi"
          >
            <b-img :src="ekspedisi ? `https://storage.googleapis.com/komerce/assets/menggunakan-ekspedisi-orange.svg` : `https://storage.googleapis.com/komerce/assets/dikirimekpedisi.svg`" />
            <span class="ml-1">Dikirim Ekspedisi</span>
          </b-button>
          <b-button
            :variant="sendiri ? 'outline-primary' : 'outline-dark'"
            class="d-flex align-items-center"
            size="sm"
            @click="onClickSendiri"
          >
            <b-img :src="sendiri ? `https://storage.googleapis.com/komerce/assets/dikirim-sendiri-orange.svg` : `https://storage.googleapis.com/komerce/assets/ekpedisidikirim-pribadi.svg`" />
            <span class="ml-1">Diambil Sendiri</span>
          </b-button>
        </div>
      </div>
      <div v-if="ekspedisi">
        <div class="flex items-start mt-1">
          <div class="w-[20%] text-[16px] text-black mt-[3px]">
            Nama Penerima
          </div>
          <div class="w-[70%]">
            <b-input
              v-model="receiverName"
              placeholder="Masukkan nama penerima"
              :formatter="formatReceiverName"
            />
            <small
              :class="receiverName.length === 30 ? 'text-primary' : ''"
              class="float-right mt-[3px]"
            >{{ receiverName.length }}/30</small>
            <small
              :class="errorInputName ? 'text-primary' : ''"
              class="float-left mt-[3px]"
            >{{ errorInputName }}</small>
          </div>
        </div>
        <div class="flex items-start mt-1">
          <div class="w-[20%] text-[16px] text-black mt-[3px]">
            No.Hp
          </div>
          <div class="w-[70%]">
            <b-input-group
              prepend="+62"
            >
              <b-input
                v-model="receiverPhone"
                number
                :formatter="formatPhoneNumber"
              />
            </b-input-group>
            <small
              :class="errorInputPhone ? 'text-primary' : ''"
              class="float-left mt-[3px]"
            >{{ errorInputPhone }}</small>
          </div>
        </div>
        <div class="flex items-center mt-1">
          <div class="w-[20%] text-[16px] text-black">
            Alamat Tujuan
          </div>
          <div class="w-[70%]">
            <v-select
              v-model="receiverDestinationId"
              :clearable="false"
              placeholder="Masukkan kelurahan/kecamatan"
              :loading="loadingDestinations"
              :options="destinations"
              @search="searchDestination"
            />
          </div>
        </div>
        <div class="flex items-start mt-1">
          <div class="w-[20%] text-[16px] text-black">
            Alamat Detail
          </div>
          <div class="w-[70%]">
            <b-textarea
              v-model="receiverAddressDetail"
              rows="3"
              placeholder="Agar kurir mudah menemukan lokasi, Masukkan nama jalan, RT / RW atau patokan bangunan dengan lengkap ya"
              :formatter="formatAddressDetail"
            />
            <small
              :class="receiverAddressDetail.length === 185 ? 'text-primary' : ''"
              class="float-right mt-[3px]"
            >{{ receiverAddressDetail.length }}/185</small>
            <small
              :class="errorAddressDetail ? 'text-primary' : ''"
              class="float-left mt-[3px]"
            >{{ errorAddressDetail }}</small>
          </div>
        </div>
      </div>
      <div v-if="sendiri">
        <div class="flex items-center mt-1">
          <div class="w-[20%] text-[16px] text-black">
            Tanggal Pengambilan
          </div>
          <div class="w-[70%]">
            <b-input-group class="">
              <flat-pickr
                ref="pickupDate"
                v-model="pickupDate"
                class="form-control"
                :config="configDate"
              />
              <b-input-group-append
                role="button"
                is-text
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/icons/date-picker-icon.svg"
                  @click="$refs.pickupDate.fp.toggle()"
                >
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
      </div>
      <div class="border-t text-black text-[16px] mt-2 flex justify-between items-center">
        <div>Stok Produk</div>
        <b-button
          class="mt-[10px]"
          :variant="disabledAddProduct ? 'outline-secondary' : 'outline-primary'"
          :disabled="disabledAddProduct"
          @click="onClickAddProduct"
        >
          Tambah Produk
        </b-button>
      </div>
      <b-table
        v-if="stokProduk.length > 0"
        :items="stokProduk"
        :fields="stokProdukFields"
        class="mt-2"
        responsive
      >
        <template #cell(variants)="data">
          <div
            v-if="data.item.is_variant"
            class="space-y-5"
          >
            <div
              v-for="item in data.item.variants"
              :key="item.variant_id"
            >
              {{ item.variant_name }}
            </div>
          </div>
          <div
            v-else
          >
            -
          </div>
        </template>
        <template #cell(stock)="data">
          <div
            v-if="data.item.is_variant"
            class="space-y-5"
          >
            <div
              v-for="item in data.item.variants"
              :key="item.variant_id"
            >
              {{ item.variant_stock }}
            </div>
          </div>
          <div
            v-else
          >
            {{ data.item.stock }}
          </div>
        </template>
        <template #cell(fulfillment_cost)="data">
          <div>
            {{ data.item.fulfillment_cost }} <span class="text-success">+{{ data.item.addonCost }}/{{ data.item.addon_minimum }}pcs</span>
          </div>
        </template>
        <template #cell(total)="data">
          <div
            v-if="data.item.is_variant"
            class="space-y-3"
          >
            <div
              v-for="(item, i) in data.item.variants"
              :key="i"
            >
              {{ item.total }}
            </div>
          </div>
          <div
            v-else
          >
            {{ data.item.total }}
          </div>
        </template>
        <template #head(pengeluaran_cost)="data">
          <div class="d-flex align-items-center">
            {{ data.label }}
            <div class="ml-[11px]">
              <img
                id="addonPopover"
                src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                alt="Komerce"
              >
              <b-popover
                triggers="hover"
                target="addonPopover"
                placement="top"
              >
                Biaya ini sudah dihitung dengan  skema biaya tambahan,
                Sehingga kamu sudah mendapatkan biaya termurah!
              </b-popover>
            </div>
          </div>
        </template>
        <template #cell(pengeluaran_cost)="data">
          <div>
            {{ data.item.totalCost = totalPengeluaran(data.item.fulfillment_cost, data.item.total, data.item.variant, data.item.addon_minimum, data.item.addon_cost) | rupiah }}
          </div>
        </template>
      </b-table>
      <div
        v-if="stokProduk.length > 0"
        class="d-flex justify-end py-2"
      >
        <div class="w-50 space-y-[12px]">
          <div class="d-flex justify-between">
            <div class="text-[16px] text-black">
              Total biaya pengeluaran
            </div>
            <div class="text-[16px] text-black ">
              {{ totalCost | rupiah }}
            </div>
          </div>
          <div class="d-flex justify-between">
            <div class="text-[16px] text-black">
              Potongan 50%
            </div>
            <div class="text-[16px] text-success ">
              {{ totalCost / 2 | rupiah }}
            </div>
          </div>
          <div class="d-flex justify-between">
            <div class="text-[20px] font-weight-bolder text-black">
              Total Pembayaran
            </div>
            <div class="text-[20px] font-weight-bolder text-primary ">
              {{ totalPayment | rupiah }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="menuAddProduct">
      <div class="d-flex items-center mb-2">
        <b-button
          variant="primary"
          size="sm"
          class="mr-1 rounded-lg p-0"
          @click="confirmResetProduct"
        >
          <feather-icon
            size="2x"
            icon="ChevronLeftIcon"
          />
        </b-button>
        <h4 class="font-bold text-black d-inline-flex mb-0">
          Tambah Produk
        </h4>
      </div>
      <BOverlay
        :show="loading"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <b-table
          id="table"
          ref="selectableTable"
          :items="barang"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
          class="mb-0 border-b"
        >
          <template #head(checkbox)="data">
            <BCheckbox
              v-model="selectAll"
              :value="data"
              @change="checkedAll()"
            />
          </template>
          <template #cell(checkbox)="data">
            <BCheckbox
              v-model="selected"
              :value="data.item"
            />
          </template>
          <template #cell(product_name)="data">
            <div
              style="max-width: 70px;"
            >
              {{ data.item.product_name }}
            </div>
          </template>
          <template #cell(variant)="data">
            <div
              v-if="data.item.is_variant"
              class="space-y-5"
            >
              <div
                v-for="item in data.item.variant"
                :key="item.variant_id"
              >
                {{ item.variant_name }}
              </div>
            </div>
            <div
              v-else
            >
              -
            </div>
          </template>
          <template #cell(stock)="data">
            <div
              v-if="data.item.is_variant"
              class="space-y-5"
            >
              <div
                v-for="item in data.item.variant"
                :key="item.variant_id"
              >
                {{ item.variant_stock }}
              </div>
            </div>
            <div
              v-else
            >
              {{ data.item.stock }}
            </div>
          </template>
          <template #cell(fulfillment_cost)="data">
            <div>
              {{ data.item.fulfillment_cost }} <span class="text-success">+{{ data.item.addon_cost }}/{{ data.item.addon_minimum }}pcs</span>
            </div>
          </template>
          <template #cell(total)="data">
            <div
              v-if="data.item.is_variant"
              class="space-y-3"
            >
              <div
                v-for="(item, i) in data.item.variant"
                :key="i"
              >
                <div class="flex">
                  <b-button
                    :variant="item.total <= 0 || disabledMinus === true ? 'outline-secondary' : 'outline-primary'"
                    class="p-0"
                    style="border-radius:50%;width:25px;height:25px"
                    size="sm"
                    :disabled="item.total <= 0 || disabledMinus === true"
                    @click="item.total -= 1, disabledSave = false"
                  >
                    <span class="font-bold text-lg">-</span>
                  </b-button>
                  <b-input
                    v-model.number="item.total"
                    number
                    class="total"
                    :formatter="numberOnly"
                    @input="disabledSave = true, disabledMinus = true, disabledPlus = true"
                    @keyup.prevent="limitToStock(data.item.is_variant, item.total, item.variant_stock, data.index, i)"
                  />
                  <b-button
                    :variant="item.total >= item.variant_stock || disabledPlus === true ? 'outline-secondary' : 'outline-primary'"
                    class="p-0"
                    style="border-radius:50%;width:25px;height:25px"
                    size="sm"
                    :disabled="item.total >= item.variant_stock || disabledPlus === true"
                    @click="item.total ++, disabledSave = false"
                  >
                    <span class="font-bold text-lg">+</span>
                  </b-button>
                </div>
              </div>
            </div>
            <div
              v-else
            >
              <div class="flex">
                <b-button
                  :variant="data.item.total <= 0 || disabledMinus === true ? 'outline-secondary' : 'outline-primary'"
                  class="p-0"
                  style="border-radius:50%;width:25px;height:25px"
                  size="sm"
                  :disabled="data.item.total <= 0 || disabledMinus === true"
                  @click="data.item.total -= 1, disabledSave = false"
                >
                  <span class="font-bold text-lg">-</span>
                </b-button>
                <b-input
                  v-model.number="data.item.total"
                  number
                  class="total"
                  :formatter="numberOnly"
                  @input="disabledSave = true, disabledMinus = true, disabledPlus = true"
                  @keyup.prevent="limitToStock(data.item.is_variant, data.item.total, data.item.stock, data.index)"
                />
                <b-button
                  :variant="data.item.total >= data.item.stock || disabledPlus === true ? 'outline-secondary' : 'outline-primary'"
                  class="p-0"
                  style="border-radius:50%;width:25px;height:25px"
                  size="sm"
                  :disabled="data.item.total >= data.item.stock || disabledPlus === true"
                  @click="data.item.total ++, disabledSave = false"
                >
                  <span class="font-bold text-lg">+</span>
                </b-button>
              </div>
            </div>
          </template>
          <template #head(pengeluaran_cost)="data">
            <div class="d-flex align-items-center">
              {{ data.label }}
              <div class="ml-[11px]">
                <img
                  id="addonPopover"
                  src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                  alt="Komerce"
                >
                <b-popover
                  triggers="hover"
                  target="addonPopover"
                  placement="top"
                >
                  Biaya ini sudah dihitung dengan  skema biaya tambahan,
                  Sehingga kamu sudah mendapatkan biaya termurah!
                </b-popover>
              </div>
            </div>
          </template>
          <template #cell(pengeluaran_cost)="data">
            <div>
              {{ data.item.totalCost = totalPengeluaran(data.item.fulfillment_cost, data.item.total, data.item.variant, data.item.addon_minimum, data.item.addon_cost) | rupiah }}
            </div>
          </template>
        </b-table>
      </BOverlay>
      <div class="d-flex justify-end border-b py-2">
        <div class="w-50 space-y-[12px]">
          <div class="d-flex justify-between">
            <div class="text-[16px] text-black">
              Total biaya pengeluaran
            </div>
            <div class="text-[16px] text-black ">
              {{ totalCost = countTotalCost() | rupiah }}
            </div>
          </div>
          <div class="d-flex justify-between">
            <div class="text-[16px] text-black">
              Potongan 50%
            </div>
            <div class="text-[16px] text-success ">
              {{ totalCost / 2 | rupiah }}
            </div>
          </div>
          <div class="d-flex justify-between">
            <div class="text-[20px] font-weight-bolder text-black">
              Total Pembayaran
            </div>
            <div class="text-[20px] font-weight-bolder text-primary ">
              {{ totalPayment = totalCost - (totalCost/2) | rupiah }}
            </div>
          </div>
        </div>
      </div>
      <!-- Handle disable if needed -->
      <b-button
        class="float-right mt-1"
        :variant="disabledSave ? 'secondary' : 'primary'"
        :disabled="disabledSave"
        @click="addProduct"
      >
        Tambahkan Produk
      </b-button>
    </div>
  </b-card>
</template>
<script>

import filters from '@/libs/filters'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Indonesian } from 'flatpickr/dist/l10n/id'
import 'flatpickr/dist/themes/light.css'
import chatButton from '@/libs/chatButton'
import 'vue-select/dist/vue-select.css'

export default {
  components: {
    vSelect,
    flatPickr,
  },
  filters: { rupiah: filters.rupiah },
  data() {
    return {
      chatButton,
      menuAddProduct: false,
      gudangList: [],
      destinations: [],

      selectAll: false,

      loading: false,
      isSelected: false,
      disabledAddProduct: true,
      selected: [],
      warehouse: '',
      whOptions: [
        {
          value: '',
          text: 'Semua Gudang',
        },
      ],
      barang: [],
      stokProduk: [],
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'text-black py-1',
          thStyle: {
            fontSize: '14px',
            color: 'black',
            textTransform: 'capitalize',
          },
          tdClass: 'text-black',
        },
        {
          key: 'product_name',
          label: 'Nama Produk',
          thClass: 'text-black py-1',
          thStyle: {
            fontSize: '14px',
            color: 'black',
            textTransform: 'capitalize',
          },
          tdClass: 'text-black',
        },
        {
          key: 'variant',
          label: 'Variasi',
          thClass: 'text-black py-1',
          thStyle: {
            fontSize: '14px',
            color: 'black',
            textTransform: 'capitalize',
          },
          tdClass: 'text-black',
        },
        {
          key: 'stock',
          label: 'Stok',
          thClass: 'text-black py-1',
          thStyle: {
            fontSize: '14px',
            color: 'black',
            textTransform: 'capitalize',
          },
          tdClass: 'text-black',
        },
        {
          key: 'fulfillment_cost',
          label: 'Biaya Fulfillment',
          thClass: 'text-black py-1',
          thStyle: {
            fontSize: '14px',
            color: 'black',
            textTransform: 'capitalize',
          },
          tdClass: 'text-black',
        },
        {
          key: 'total',
          label: 'Jumlah Pengajuan',
          thClass: 'text-black py-1',
          thStyle: {
            fontSize: '14px',
            color: 'black',
            textTransform: 'capitalize',
          },
          tdClass: 'text-black',
        },
        {
          key: 'pengeluaran_cost',
          label: 'Biaya Pengeluaran',
          thClass: 'text-black py-1',
          thStyle: {
            fontSize: '14px',
            color: 'black',
            textTransform: 'capitalize',
          },
          tdClass: 'text-black',
        },
      ],
      stokProdukFields: [
        {
          key: 'product_name',
          label: 'Nama Produk',
          thClass: 'text-black py-1',
          thStyle: {
            fontSize: '14px',
            color: 'black',
            textTransform: 'capitalize',
          },
          tdClass: 'text-black',
        },
        {
          key: 'variants',
          label: 'Variasi',
          thClass: 'text-black py-1',
          thStyle: {
            fontSize: '14px',
            color: 'black',
            textTransform: 'capitalize',
          },
          tdClass: 'text-black',
        },
        {
          key: 'stock',
          label: 'Stok',
          thClass: 'text-black py-1',
          thStyle: {
            fontSize: '14px',
            color: 'black',
            textTransform: 'capitalize',
          },
          tdClass: 'text-black',
        },
        {
          key: 'fulfillment_cost',
          label: 'Biaya Fulfillment',
          thClass: 'text-black py-1',
          thStyle: {
            fontSize: '14px',
            color: 'black',
            textTransform: 'capitalize',
          },
          tdClass: 'text-black',
        },
        {
          key: 'total',
          label: 'Jumlah Pengajuan',
          thClass: 'text-black py-1',
          thStyle: {
            fontSize: '14px',
            color: 'black',
            textTransform: 'capitalize',
          },
          tdClass: 'text-black',
        },
        {
          key: 'pengeluaran_cost',
          label: 'Biaya Pengeluaran',
          thClass: 'text-black py-1',
          thStyle: {
            fontSize: '14px',
            color: 'black',
            textTransform: 'capitalize',
          },
          tdClass: 'text-black',
        },
      ],
      configDate: {
        wrap: true,
        altFormat: 'j F Y',
        altInput: true,
        minDate: 'today',
        altInputClass: 'bg-white form-control',
        locale: Indonesian,
      },
      disabledSave: true,
      disabledMinus: false,
      disabledPlus: false,

      receiverName: '',
      errorInputName: 'Nama penerima wajib diisi',
      receiverPhone: '',
      errorInputPhone: 'No HP wajib diisi',
      receiverDestinationId: '',
      receiverAddressDetail: '',
      errorAddressDetail: 'Alamat detail wajib diisi',
      pickupDate: null,
      ekspedisi: false,
      sendiri: false,
      loadingDestinations: false,
      totalCost: 0,
      totalPayment: 0,
    }
  },
  created() {
    this.fetchDataWarehouse()
  },
  methods: {
    async searchDestination(query) {
      if (query.length >= 2) {
        this.loadingDestinations = true
        await this.$http_komship.get('/v2/destination', {
          params: {
            search: query,
          },
        })
          .then(res => {
            const { data } = res.data.data
            this.destinations = data.map(item => ({
              label: item.label,
              value: item.id,
            }))
            this.loadingDestinations = false
          })
          .catch(err => {
            this.loadingDestinations = false
          })
      }
    },
    async fetchData() {
      this.selected = []
      this.disabledAddProduct = true
      this.loading = true
      await this.$http_komship.get(`/v1/komship/outbound/product/${this.warehouse}`)
        .then(res => {
          const { data } = res.data
          const updated = data.map(item => {
            if (item.is_variant === 0) {
              return { ...item, total: 0, totalCost: 0 }
            }
            const modifiedVariants = item.variant.map(variant => ({ ...variant, total: 0 }))
            return { ...item, variant: modifiedVariants, totalCost: 0 }
          })
          this.barang = updated
          this.disabledAddProduct = false
          this.loading = false
        }).catch(() => {
          this.loading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Gagal load data, silahkan coba lagi',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    async fetchDataWarehouse() {
      await this.$http_komship.get('/v1/komship/outbound/warehouses')
        .then(res => {
          const { data } = res.data
          this.gudangList = data.map(obj => ({
            label: obj.outbound_queue === 1 ? `${obj.warehouse_name} (Masih ada antrian)` : obj.warehouse_name,
            value: obj.warehouse_id,
            disabled: obj.outbound_queue === 1,
          }))
        }).catch(() => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Gagal load data, silahkan coba lagi',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    checkedAll() {
      if (this.selectAll) {
        this.selected = this.barang
      } else {
        this.selected = []
      }
    },
    handleCheckbox() {
      if (this.selected.length === this.barang.length) {
        this.selectAll = true
      } else {
        this.selectAll = false
      }
    },
    toggleAllRows() {
      if (this.isSelected) {
        this.$refs.selectableTable.selectAllRows()
      }
      if (!this.isSelected) {
        this.$refs.selectableTable.clearSelected()
      }
    },
    confirmAjukan() {
      this.$swal({
        title: 'Pengajuan Penarikan barang',
        text: 'Anda yakin menyutujui pengajuan? saldo kamu akan berkurang untuk biaya penarikan barang',
        icon: 'warning',
        width: '50%',
        reverseButtons: true,
        iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
        showCancelButton: true,
        cancelButtonText: 'Batal',
        confirmButtonText: 'Setujui',
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          icon: 'border-0 w-50 my-5',
          confirmButton: 'btn btn-primary px-4',
          cancelButton: 'btn btn-outline-primary mr-1 px-5',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.hitApi()
        }
      })
    },
    async hitApi() {
      const payload = {
        partner_id: JSON.parse(localStorage.getItem('userData')).partner_detail.id,
        // eslint-disable-next-line radix
        warehouse_id: parseInt(this.warehouse),
        fulfillment_fee: this.totalPayment,
        shipping_type: this.sendiri ? 1 : 2,
        pickup_date: this.pickupDate === null ? null : this.pickupDate,
        receiver_name: this.receiverName === '' ? null : this.receiverName,
        receiver_phone: this.receiverPhone === '' ? null : this.receiverPhone,
        receiver_destination_id: this.receiverDestinationId === '' ? null : this.receiverDestinationId.value,
        receiver_address_detail: this.receiverAddressDetail === '' ? null : this.receiverAddressDetail,
        products: this.stokProduk,
      }

      await this.$http_komship.post('/v1/komship/outbound/store', payload)
        .then(res => {
          const { data } = res
          if (data.code === 200) {
            this.$swal({
              title: 'Pengajuan berhasil dikirim',
              text: 'Menunggu persetujuan mitra gudang. untuk koordinasi lebih lanjut kamu bisa menghubungi mitra gudang',
              icon: 'success',
              width: '50%',
              reverseButtons: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-success.png">',
              showCancelButton: true,
              cancelButtonText: 'Kembali',
              confirmButtonText: 'Hubungi mitra',
              customClass: {
                icon: 'border-0 w-50 my-5',
                confirmButton: 'btn btn-primary px-4',
                cancelButton: 'btn btn-outline-primary mr-1 px-5',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                this.$router.push({
                  path: '/penarikan-barang',
                })
                this.chatButton(data.data.pic_phone)
              } else {
                this.$router.push({
                  path: '/penarikan-barang',
                })
              }
            })
          } else if (data.code === 400) {
            this.$swal({
              title: 'Pengajuan gagal dikirim',
              text: 'Kamu tidak dapat melakukan pengajuan karena masih memiliki antrian pengajuan',
              icon: 'success',
              width: '50%',
              allowOutsideClick: false,
              allowEscapeKey: false,
              iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
              confirmButtonText: 'Oke',
              customClass: {
                icon: 'border-0 w-50 my-5',
                confirmButton: 'btn btn-primary px-4',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                this.$router.push({
                  path: '/penarikan-barang',
                })
              }
            })
          } else if (data.code === 1006) {
            this.$swal({
              title: 'Pengajuan gagal dikirim',
              text: 'Saldo kamu tidak mencukupi untuk pengajuan penarikan barang, isi saldo kamu yuk...',
              icon: 'success',
              width: '50%',
              reverseButtons: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              iconHtml: '<img src="https://storage.googleapis.com/komerce/assets/icons/fail.svg">',
              showCancelButton: true,
              cancelButtonText: 'Kembali',
              confirmButtonText: 'Top Up',
              customClass: {
                icon: 'border-0 w-50 my-5',
                confirmButton: 'btn btn-primary px-4',
                cancelButton: 'btn btn-outline-primary mr-1 px-5',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                this.$router.push({
                  name: 'saldo',
                })
              } else {
                this.$router.push({
                  path: '/penarikan-barang',
                })
              }
            })
          }
        })
        .catch(() => {})
    },
    countTotalPay() {
      const result = this.selected.reduce((acc, item) => {
        if (this.disabledSave === false) {
          if (item.is_variant === 1) {
          // eslint-disable-next-line no-shadow
            return acc + item.variant.reduce((acc, variant) => acc + variant.total * item.fulfillment_cost, 0)
          }
          return acc + item.total * item.fulfillment_cost
        }
        return 0
      }, 0)
      return result
    },
    countTotalCost() {
      const result = this.selected.reduce((acc, item) => {
        if (this.disabledSave === false) {
          return acc + item.totalCost
        }
        return 0
      }, 0)
      return result
    },
    formatReceiverName(value) {
      if (value.length === 0) {
        this.errorInputName = 'Nama penerima wajib diisi'
        return value
      }
      const alphabet = value.replace(/[^a-zA-Z ]/g, '')
      if (alphabet.length < 3) {
        this.errorInputName = 'Minimal 3 huruf'
        return alphabet
      }
      this.errorInputName = ''
      return alphabet.slice(0, 30)
    },
    formatAddressDetail(value) {
      if (value.length === 0) {
        this.errorAddressDetail = 'Alamat detail wajib diisi'
        return value
      }
      this.errorAddressDetail = ''
      return value.slice(0, 185)
    },
    formatPhoneNumber(value) {
      if (value.length === 0) {
        this.errorInputPhone = 'No HP wajib diisi'
        return value
      }
      // Replace any non-numeric characters with an empty string
      const numericValue = value.replace(/[^\d]/g, '')

      // check if the number starts with 62
      if (numericValue.startsWith('62')) {
        return numericValue.substr(2)
      }

      // If the first character is not 8, remove it
      if (numericValue[0] !== '8') {
        this.errorInputPhone = 'Harus diawali dengan angka 8'
        return numericValue.slice(1)
      }
      if (numericValue.length < 9) {
        this.errorInputPhone = 'Minimal 9 digit'
        return numericValue
      }

      // Otherwise, return the numeric value
      this.errorInputPhone = ''
      return numericValue.substr(0, 13)
    },
    onClickEkspedisi() {
      this.ekspedisi = true
      this.sendiri = false
      this.pickupDate = null
    },
    onClickSendiri() {
      this.ekspedisi = false
      this.sendiri = true
      this.receiverName = ''
      this.receiverPhone = ''
      this.receiverDestinationId = ''
      this.receiverAddressDetail = ''
    },
    numberOnly(value) {
      return value ? value.replace(/[^\d]/g, '').slice(0, 4) : 0
    },
    limitToStock(isVariant, total, stock, index, varIndex) {
      if (isVariant === 1) {
        this.barang[index].variant[varIndex].total = total > stock ? stock : total
      } else {
        this.barang[index].total = total > stock ? stock : total
      }
      this.disabledSave = false
      this.disabledMinus = false
      this.disabledPlus = false
    },
    addProduct() {
      const transformedData = this.selected.map(item => {
        if (item.is_variant) {
          const total = item.variant.reduce((acc, cur) => acc + cur.total, 0)
          return {
            ...item,
            product_id: item.id,
            total,
            variants: item.variant.map(variant => ({
              ...variant,
              warehouse_variant_id: variant.variant_id,
            })),
          }
        }
        return {
          ...item,
          product_id: item.id,
          variants: item.variant,
        }
      })
      const filteredVar = transformedData.map(product => ({
        ...product,
        variants: product.variants.filter(variant => variant.total > 0),
      }))
      const FinalFilteredVar = filteredVar.filter(product => product.total > 0)
      this.stokProduk = FinalFilteredVar
      this.menuAddProduct = false
    },
    handleDisableAjukan() {
      if (!this.ekspedisi && !this.sendiri) return true
      if (this.ekspedisi) {
        if (this.stokProduk.length === 0 || this.errorInputName || this.errorInputPhone || this.receiverDestinationId === '' || this.errorAddressDetail) {
          return true
        }
        return false
      }
      if (this.sendiri) {
        if (this.stokProduk.length === 0 || this.pickupDate === null) {
          return true
        }
        return false
      }
      return false
    },
    confirmResetProduct() {
      this.$swal({
        title: 'Batalkan Tambah Stok Produk?',
        text: 'Data produk yang sudah kamu masukkan sebelumnya akan direset',
        icon: 'warning',
        reverseButtons: true,
        iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
        showCancelButton: true,
        cancelButtonText: 'Tidak',
        confirmButtonText: 'Iya',
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          icon: 'border-0 w-50 my-5',
          confirmButton: 'btn btn-primary px-4',
          cancelButton: 'btn btn-outline-primary mr-1 px-5',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.menuAddProduct = false
          this.fetchData()
        }
      })
    },
    onClickAddProduct() {
      if (this.stokProduk.length > 0) {
        this.fetchData().then(() => {
          for (let i = 0; i < this.barang.length; i += 1) {
            const originalItem = this.barang[i]
            const desiredItem = this.stokProduk.find(item => item.id === originalItem.id)

            if (desiredItem) {
              originalItem.total = desiredItem.total

              if (originalItem.is_variant) {
                for (let j = 0; j < originalItem.variant.length; j += 1) {
                  const originalVariant = originalItem.variant[j]
                  const desiredVariant = desiredItem.variant.find(variant => variant.option_id === originalVariant.option_id)

                  if (desiredVariant) {
                    originalVariant.total = desiredVariant.total
                  }
                }
              }
              this.selected.push(originalItem)
            }
          }
        })
      }
      this.menuAddProduct = true
    },
    totalPengeluaran(bd, total, variant, addonMin, addonCost) {
      if (variant.length > 0) {
        const totalVariant = variant.reduce((accumulator, currentItem) => accumulator + currentItem.total, 0)
        const jumlahMinPcs = totalVariant - addonMin
        if (totalVariant === 0) {
          return 0
        }
        return (bd + (Math.ceil(jumlahMinPcs / addonMin) * addonCost))
      }
      const jumlahMinPcs = total - addonMin
      if (total === 0) {
        return 0
      }
      return (bd + (Math.ceil(jumlahMinPcs / addonMin) * addonCost))
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .vs__dropdown-menu {
  max-height: 130px;
}
</style>
<style scoped>
.total {
  width: 25%;
  border-bottom: solid 2px #e2e2e2 !important;
  border: none;
  border-radius: 0;
  outline: none;
  margin: 0px 5px;
  padding: 2px 0px;
  text-align: center;
}
</style>
